@media (max-width: 768px) {
    ::-webkit-scrollbar {
        display: none;
    }
}

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}

.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollable::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 12px; /* Adjust this value to change the height of the fade effect */
    background: linear-gradient(to bottom, var(--scrollable-background, #ffffff) 0%, transparent 100%);
    z-index: 999;
    border-radius: 10px;
}
.scrollable::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12px; /* Adjust this value to change the height of the fade effect */
    background: linear-gradient(to bottom, transparent 0%, var(--scrollable-background, #ffffff) 100%);
    pointer-events: none; /* This allows clicks to pass through the pseudo-element */
    z-index: 999;
    border-radius: 10px;
}

.radialGaugeLabel {
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    fill: #101828 !important;
    display: flex !important;
}

.radialGaugeDescriptionLabel {
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    fill: #475467 !important;
    display: flex !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: "Frutiger W01";
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url("https://assets.nhs.uk/fonts/FrutigerLTW01-65Bold.eot?#iefix");
    src: url("https://assets.nhs.uk/fonts/FrutigerLTW01-65Bold.eot?#iefix")
            format("eot"),
        url("https://assets.nhs.uk/fonts/FrutigerLTW01-65Bold.woff2")
            format("woff2"),
        url("https://assets.nhs.uk/fonts/FrutigerLTW01-65Bold.woff")
            format("woff"),
        url("https://assets.nhs.uk/fonts/FrutigerLTW01-65Bold.ttf")
            format("truetype"),
        url("https://assets.nhs.uk/fonts/FrutigerLTW01-65Bold.svg#eae74276-dd78-47e4-9b27-dac81c3411ca")
            format("svg");
}
