/* Useful pages for dealing with rendering on iOS::after
- https://gist.github.com/nicolaskopp/637aa4e20c66fe41a6ea2a0773935f6e
- https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
*/

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100lvh;
    min-height: -webkit-fill-available;
    width: 100lvw;
}

html {
    height: -webkit-fill-available;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

:root                  { box-sizing: border-box; }
*, *::before, *::after { box-sizing: inherit; }

:root, body {
    margin: 0;
    padding: 0;
    border: initial;
}
